.portfolioWrapper {
    margin-bottom: 150px;
}

.portfolioHeader {
    text-align: center;
    color: white;
    margin-top: 100px;
}

ul {
    list-style-type: none;
    color: white;
}

.flex-row {
	display: flex;
	justify-content: space-evenly;
}

.card {
    width: 40rem;
	text-align: center;
}

.card-body {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1.6;
}

.card-title {
	font-size: 1.5rem;
}

.card-subtitle {
	font-size: 1rem;
}

.card-text {
	font-size: 18px;
	height: 5rem;

}

.card-image {
	width: 500px;
	height: 250px;
    border: double 8px transparent;
    border-radius: 80px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #efd5ff 0%, #515ada 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
/*
background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);
*/
.card-link {
	font-size: 20px;
    line-height: 1.6;
    text-decoration: none !important;
    color: white;
}

.card-link:hover {
    color: greenyellow;
}