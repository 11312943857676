.aboutMeWrapper {
    margin-top: 200px;
    display: flex;
    flex-flow: row wrap;
    color: white;
    font-size: 40px;
    line-height: 1.6;
}

.profilePicContainer {
    width: 35%;
    text-align: right;
    margin-left: 50px;
}

.profilePic {
    width: 250px;
    height: 300px;
    border: 2px solid black;
    border-radius: 50%;
}

.myName {
    flex: 1;
    text-align: left;
    padding-top: 80px;
    margin-left: 10px;
}

.myBio {
    text-align: center;
    width: 100%;
}


