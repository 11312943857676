.footerLinksContainer a svg{
    width: 100px;
    height: 28px;
    color: white;
    padding: 20px;
    bottom: 50px;
}

.footerLinksContainer {
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0px;
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
}