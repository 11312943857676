html, body {
    margin: 0;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.homepageWrap {
  overflow:scroll;
  position: fixed;
  width: 101vw;
  height: 110vh;
  scroll-padding-top: 100px;
  left: 0;
  background: linear-gradient(to right, #283c86, #45a247);
}

.homepageBackground {
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.homepageContent {
  position: relative;
}

@media screen and (max-width: 980px) {
    .homepageContent {
        overflow-x: hidden;
    }
    body {
        position: relative;
    }
}

@media screen and (max-width: 768px) {
    .homepageContent {
        overflow-x: hidden;
    }
    body {
        position: relative;
    }
}